/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/prismjs@1.29.0/components/prism-core.min.js
 * - /npm/prismjs@1.29.0/plugins/autoloader/prism-autoloader.min.js
 * - /npm/prismjs@1.29.0/plugins/toolbar/prism-toolbar.min.js
 * - /npm/prismjs@1.29.0/plugins/copy-to-clipboard/prism-copy-to-clipboard.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
